export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [2,4,6,7,8,3];

export const dictionary = {
		"/auth/[provider]": [~49,[13]],
		"/auth/[provider]/info": [~50,[13]],
		"/auth/[provider]/info/login": [~51,[13]],
		"/auth/[provider]/login": [~52,[13]],
		"/auth/[provider]/reconnect": [~53,[13]],
		"/(app)/dashboard": [20,[2,4],[,5]],
		"/(app)/dashboard/organization": [35,[2,4],[,5]],
		"/(app)/dashboard/organization/billing": [36,[2,4],[,5]],
		"/(app)/dashboard/organization/migration": [37,[2,4],[,5]],
		"/(app)/dashboard/organization/settings": [38,[2,4],[,5]],
		"/(app)/dashboard/organization/uploads": [39,[2,4],[,5]],
		"/(app)/dashboard/organization/users": [40,[2,4],[,5]],
		"/(app)/dashboard/other": [41,[2,4],[,5]],
		"/(app)/dashboard/other/ab-testing": [42,[2,4],[,5]],
		"/(app)/dashboard/other/admin": [~43,[2,4],[,5]],
		"/(app)/dashboard/other/roadmap": [~44,[2,4],[,5]],
		"/(app)/dashboard/user/profile": [45,[2,4],[,5]],
		"/(app)/dashboard/[workspace_key]": [21,[2,4,6],[,5]],
		"/(app)/dashboard/[workspace_key]/forms": [22,[2,4,6,7],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]": [23,[2,4,6,7,8],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/analytics": [24,[2,4,6,7,8,9],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/analytics/engagement": [25,[2,4,6,7,8,9],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/analytics/field": [26,[2,4,6,7,8,9],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/analytics/overview": [27,[2,4,6,7,8,9],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/edit": [28,[2,4,6,7,8],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/responses": [29,[2,4,6,7,8],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/share": [30,[2,4,6,7,8,10],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/share/embed-advanced": [31,[2,4,6,7,8,10],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/share/embed-email": [32,[2,4,6,7,8,10],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/share/embed-page": [33,[2,4,6,7,8,10],[,5]],
		"/(app)/dashboard/[workspace_key]/forms/[form_key]/share/link": [34,[2,4,6,7,8,10],[,5]],
		"/(app)/(access)/forgot-password": [14,[2,3]],
		"/(app)/(access)/invite": [15,[2,3]],
		"/(app)/(access)/login": [~16,[2,3]],
		"/public/screenshots/design": [~54],
		"/public/screenshots/engagement": [~55],
		"/public/screenshots/field-analytics": [~56],
		"/public/screenshots/logic": [~57],
		"/public/screenshots/share-email": [~58],
		"/(app)/salesforce/canvas": [~46,[2,11]],
		"/(app)/salesforce/login": [~47,[2,11]],
		"/(app)/(access)/signup": [~17,[2,3]],
		"/(app)/(access)/two-factor": [18,[2,3]],
		"/(app)/(access)/update-password": [19,[2,3]],
		"/[[key]]": [~48,[],[12]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';